import { RefreshIcon } from "@heroicons/react/solid";
import React, { useContext } from "react";
import { Logo, Tab } from "ww-framework";
import orgBgDefault from "ww-images/org-bg-default.jpg";
import { OrganisationContext } from "ww-stores";
import AttendanceView from "./AttendanceView";
import ClockInOutView from "./ClockInOutView";

const Board = () => {
	const { organisation } = useContext(OrganisationContext);
	const [activeTab, setActiveTab] = React.useState(0);

	const tabs = [
		{
			id: 0,
			name: "Clock In/Out",
			view: <ClockInOutView />
		},
		{
			id: 1,
			name: "Attendance",
			view: <AttendanceView />
		}
	];
	return (
		<>
			<div>
				<div>
					<img className="w-full object-cover h-40 lg:h-80" src={orgBgDefault} alt="" />
					<div className="absolute inset-0 bg-gray-400 mix-blend-multiply h-48 lg:h-80" aria-hidden="true" />
				</div>
				<div className="relative z-10 max-w-7xl min-h-fit mx-auto -mt-60 sm:-mt-40 lg:-mt-80">
					<div className="h-44 sm:h-24 pt-24 sm:pt-2 flex flex-col sm:flex-row sm:items-center sm:justify-between">
						<div>
							<div className="flex lg:pl-10 gap-2 mb-2 sm:ml-12 justify-center">
								<Logo org={organisation} size="h-16 w-16 mt-1" />
								<div>
									<p className="text-2xl font-extrabold tracking-tight text-white text-start lg:text-left md:text-3xl lg:text-4xl">
										{organisation.name}
									</p>
									<div className="text-xs font-bold tracking-tight text-white text-start lg:text-left md:text-md lg:text-md">
										{organisation.address}
									</div>
									<div className="text-xs font-bold tracking-tight text-white text-start lg:text-left md:text-md lg:text-md">
										{organisation.phone}
									</div>
								</div>
							</div>
						</div>
						<div className="mr-8 hidden sm:flex sm:justify-start sm:items-center">
							<div className="border-2 border-darkww-500 rounded py-1 px-3 hover:border-white hover:text-white">
								<RefreshIcon
									className="h-6 w-6 ml-1 text-white cursor-pointer"
									onClick={() => window.location.reload()}
								/>
							</div>
						</div>
					</div>
					<div className=" mt-3 px-2">
						<Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
							<Tab.List className="flex justify-center">
								{/* {tabs.map((tab, index) => (
									<Tab key={tab.name}>
										{({ selected }) => (
											<div
											className={`flex items-center px-4 h-3 shadow-md py-5 ${
												selected ? "bg-white " : "bg-white bg-opacity-80" 
											  } ${
												index === 0 ? "rounded-tl-lg" : "" 
											  } ${
												index === tabs.length - 1 ? "rounded-tr-lg" : ""
											  } ${
												index !== tabs.length - 1 ? "border-r border-gray-400" : ""
											  }`}
										  >
												<div
													className={`font-medium ${selected ? "text-blue-600" : "text-black"}`}
												>
													{tab.name}
												</div>
											</div>
										)}
									</Tab>
								))} */}
							</Tab.List>
							<Tab.Panels className="mt-1">
								{tabs.map((tab, index) => (
									<Tab.Panel key={index}>{tab.view}</Tab.Panel>
								))}
							</Tab.Panels>
						</Tab.Group>
					</div>
				</div>
			</div>
		</>
	);
};

export default Board;
