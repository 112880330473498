import React, { createContext, useState } from "react";

export const RepositoryContext = createContext();

export const RepositoryProvider = ({ children }) => {
	// store weeks shifts in hash maps where key is the weekStartDay
	/**
     * { "20210906": [shift1, shift2, shift3], "2021-09-13": [shift1, shift2, shift3] }
     */
	const [reloadData, setReloadData] = useState(false);
	const [cachedWeeksShifts, setCachedWeeksShifts] = useState({});
	const [cachedWeeksPunches, setCachedWeeksPunches] = useState({});

	return (
		<RepositoryContext.Provider
			value={{
				reloadData,
				setReloadData,
				cachedWeeksShifts,
				setCachedWeeksShifts,
				cachedWeeksPunches,
				setCachedWeeksPunches
			}}
		>
			{children}
		</RepositoryContext.Provider>
	);
};
