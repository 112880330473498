import { OrganisationProvider, PersonProvider, RepositoryProvider, SubscriptionProvider } from "ww-stores";
import { SimpleMessage } from "../Modals/SimpleMessage.jsx";

export const AppProviders = ({ children }) => {
	return (
		<RepositoryProvider>
			<PersonProvider>
				<OrganisationProvider>
					<SubscriptionProvider>
						<SimpleMessage>{children}</SimpleMessage>
					</SubscriptionProvider>
				</OrganisationProvider>
			</PersonProvider>
		</RepositoryProvider>
	);
};
