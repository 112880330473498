import { API, graphqlOperation } from "aws-amplify";
import { createContext, useState } from "react";
import {
	createDisciplinaryRecord as AWSCreateDisciplinaryRecord,
	createInvitation as AWSCreateInvitation,
	deleteDisciplinaryRecord as AWSDeleteDisciplinaryRecord,
	deleteInvitation as AWSDeleteInvitation,
	createOrganisation,
	createOrganisationMember,
	createTemplate,
	deleteOrganisationMember,
	deleteTemplate,
	updateOrganisation,
	updateOrganisationMember
} from "../../graphql/mutations.js";
import { getOrgTemplate as AWSGetOrgTemplates, getOrganisation as AWSGetOrganisation, getMemberByOrgId } from "../../graphql/queries.js";
import { getPreferences as AWSGetPreferences, savePreference as AWSSavePreference, deletePreference } from "./preferences";
import {
	destroyAllGiveAway as AWSDestroyAllGiveAwayShift,
	destroyAllMemberSwapShift as AWSDestroyAllMemberSwapShift,
	destroyAllMemberSwapShiftByShiftId as AWSDestroyAllRequest,
	destroyGiveAway as AWSDestroyGiveAwayShift,
	getAllGiveAway as AWSGetAllGiveAway,
	getAllRequest as AWSGetAllRequest,
	getFromMemberRequests as AWSGetFromMemberRequests,
	getMemberGiveAway as AWSGetMemberGiveAway,
	getMemberRequests as AWSGetMemberRequests,
	getOrgRequests as AWSGetOrgRequests,
	getOrganisationMemberGiveAway as AWSGetOrganisationMemberGiveAway,
	getOrganisationMembersGiveAways as AWSGetOrganisationMembersGiveAway,
	saveAllGiveAway as AWSSaveAllGiveAway,
	saveAllRequest as AWSSaveAllRequest,
	saveGiveAway as AWSSaveGiveAway,
	saveRequest as AWSSaveRequest
} from "./requests";
import { deleteRole, saveRole as saveOrgRole } from "./roles";
import {
	getCurrentYearShifts as AWSGetCurrentYearShifts,
	getMemberYearShifts as AWSGetMemberYearShifts,
	getMemberYearShiftsByMonth as AWSGetMemberYearShiftsByMonth,
	getShiftOnDayOfMember as AWSGetShiftOnDayOfMember,
	getShifts as AWSGetShifts,
	// getShiftsForTheWeek as AWSGetShiftsForTheWeek,
	giveAwayShift as AWSGiveAwayShift,
	moveShift as AWSMoveShift,
	publishShift as AWSPublishShift,
	replaceShift as AWSReplaceShift,
	saveShift as AWSSaveShift,
	swapRequest as AWSSwapRequest,
	swapShift as AWSSwapShift,
	deleteShift as AWSdeleteShift,
	addClockIn,
	approvePendingClockIn,
	changeBaseDayAndMember,
	denyPendingClockIn,
	getDayShifts,
	getMemberWeekShift,
	getPendingClockIns
} from "./shifts";
import {
	changeStatusTimeOff as AWSChangeStatusTimeOff,
	destroyTimeOff as AWSDestroyTimeOff,
	getMemberTimeOff as AWSGetMemberTimeOff,
	getPaidTimeOff as AWSGetPaidTimeOff,
	getTimeOff as AWSGetTimeOff,
	saveTimeOff as AWSSaveTimeOff
} from "./timeOff";

import { Storage } from "ww-framework";
import { personUtils } from "ww-stores";
import { deleteCheckIn, saveCompletedCheckIn, saveCheckIn as saveOrgCheckIn } from "./checkIns";
import { deleteDepartment, saveDepartment as saveOrgDepartment } from "./departments";
import { getExtraPayDay, saveExtraPayDay } from "./extraPayDay";
import { deleteFileById, deleteFolderById, getFolderByFilter, getOrganisationFiles, getOrganisationFolders, saveFile, saveFolder } from "./files";
import { deleteLeaveOption, deleteLeaveRecord, saveLeaveOption, saveLeaveRecord } from "./leaveOptions.js";
import {
	deleteAllMessage as AWSDeleteAllMessage,
	getDeptMessage as AWSGetOrgDeptMessage,
	getOrgGroupMessage as AWSGetOrgGroupMessage,
	getRoleMessage as AWSGetOrgRoleMessage,
	readGroupMessage as AWSReadGroupMessage,
	saveDeptMessage as AWSSaveDeptMessage,
	saveMessage as AWSSaveMessage,
	saveOrgGroupMessage as AWSSaveOrgGroupMessage,
	saveRoleMessage as AWSSaveRoleMessage,
	readMessage as AWSreadMessage,
	unReadMessages as AWSunReadMessages,
	getOrganisationMessage
} from "./message.js";
import {
	destroyNoteParticularDay as AWSDestroyNoteParticularDay,
	getNoteParticularDay as AWSGetNoteParticularDay,
	saveNoteParticularDay as AWSSaveNoteParticularDay
} from "./noteParticularDay";
import { deleteNote as AWSDeleteNote, saveNote as AWSSaveNote, getNotesByShift } from "./notes";
import {
	addCommentToNotice as AWSAddComment,
	deleteNoticeById as AWSDeleteNotice,
	deleteNoticeCommentById as AWSDeleteNoticeComment,
	getNoticesByOrganisation as AWSGetNoitice,
	saveNoitice as AWSSaveNoitice
} from "./notice";
import { deletePunch, getDayPunches, getDayPunchesBetweenDates, savePunch } from "./punch";
import {
	getAverageOfDay as AWSGetAverageOfDay,
	getReport as AWSGetReport,
	getReportsByMonth as AWSGetReportsByMonth,
	getWeekReport as AWSGetWeekReport,
	saveReport as AWSSaveReport,
	destroyReport as AWSdestroyReport
} from "./report";
import { getShiftTemplates as AWSGetShiftTemplates, saveShiftTemplate as AWSSaveShiftTemplate, destroyShiftTemplate } from "./shiftTemplate";
import { deleteStockById as AWSDeleteStock, getOrganisationStock as AWSGetStock, saveStock as AWSSaveStock } from "./stock";
import {
	cancelSubscription as cancelSubscriptionApi,
	createSubscriptionId as createSessionApi,
	updateSubscription as updateSubscriptionApi
} from "./subscription.js";

const organisationSchema = {
	name: "",
	owner: "",
	phone: "",
	hrEmail: "",
	paidBreak: "",
	allPaidBreak: "",
	autoBreak: "",
	address: "",
	logoURI: "",
	StaffCost: "",
	vat: null,
	foodPercentage: null,
	drinkPercentage: null,
	startDay: null
};
const subscriptionSchema = {
	SubscriptionStatus: "",
	expiryAt: "",
	quantity: ""
};

const saveOrganisation = (orgDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			let organisationMapped = {
				id: orgDetails.id,
				name: orgDetails.name,
				address: orgDetails.address,
				phone: orgDetails.phone,
				hrEmail: orgDetails.hrEmail,
				paidBreak: orgDetails.paidBreak,
				allPaidBreak: orgDetails.allPaidBreak,
				autoBreak: orgDetails.autoBreak,
				shiftCompliance: orgDetails.shiftCompliance,
				logoURI: orgDetails.logoURI,
				StaffCost: orgDetails.StaffCost,
				vat: orgDetails.vat,
				drinkPercentage: orgDetails.drinkPercentage,
				foodPercentage: orgDetails.foodPercentage,
				startDay: orgDetails.startDay,
				staffInputhourlyRate: orgDetails.staffInputhourlyRate,
				staffClockInView: orgDetails.staffClockInView,
				staffHolidayPay: orgDetails.staffHolidayPay,
				disableClockIn: orgDetails.disableClockIn,
				enablePhoto: orgDetails.enablePhoto,
				lateClockOutNotificationHours: orgDetails.lateClockOutNotificationHours,
				enableStaffTimeOff: orgDetails.enableStaffTimeOff,
				enableStaffPreferences: orgDetails.enableStaffPreferences,
				earlyClockInNotificationHours: orgDetails.earlyClockInNotificationHours
			};

			const mutation = orgDetails.id ? updateOrganisation : createOrganisation;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: organisationMapped }
			});
			let savedOrganisation = data[orgDetails.id ? "updateOrganisation" : "createOrganisation"];
			savedOrganisation = addExtraFieldsToOrg(savedOrganisation);

			resolve({ ...orgDetails, ...savedOrganisation });
		} catch (error) {
			reject(error);
		}
	});
};

const addExtraFieldsToOrg = (dbOrg) => {
	const allMembers = dbOrg.members;
	let currentMembers = allMembers.items.filter((member) => member.status !== "DELETED");
	let deletedMembers = allMembers.items.filter((member) => member.status === "DELETED");
	currentMembers = { ...allMembers, items: currentMembers };
	deletedMembers = { ...allMembers, items: deletedMembers };
	const updatedDbOrg = {
		...dbOrg,
		members: currentMembers,
		deletedMembers: deletedMembers,
		allMembers: allMembers,
		checkIns: dbOrg.checkIns?.items
	};
	return updatedDbOrg;
};

const getOrganisation = async (orgDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: AWSGetOrganisation,
				variables: { id: orgDetails.id }
			});

			const { getOrganisation: dbOrg } = data;
			const updatedDbOrg = addExtraFieldsToOrg(dbOrg);

			resolve({ ...orgDetails, ...updatedDbOrg });
		} catch (error) {
			reject(error);
		}
	});
};

const addMember = async (memberId, orgId, email, name, id = null, isPayRoll = false) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: id || null,
				personID: memberId,
				organisationID: orgId,
				email: email || null,
				orgUsername: name,
				isPayRoll: isPayRoll
			};
			const mutation = id ? updateOrganisationMember : createOrganisationMember;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: orgMemberDetails }
			});
			const savedMember = data[id ? "updateOrganisationMember" : "createOrganisationMember"];
			const dbOrgMember = { ...orgMemberDetails, ...savedMember };
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const assignAsAdminOrgMember = async (isAdmin, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				assignedAdmin: isAdmin,
				assignedFinancials: isAdmin
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const assignFinancialsForAssignedAdminMember = async (assignFinancials, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				assignedFinancials: assignFinancials
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const assignAsRequestsOrgMember = async (isRequests, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				assignedRequests: isRequests
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const assignAsSuperAdminOrgMember = async (isSuperAdmin, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				assignedSuperAdmin: isSuperAdmin
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const addRoleToOrgMember = async (roleid, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				roleID: roleid
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};

const addHourlyRateToOrgMember = async (rate, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				huorlyRate: rate
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const addRateUnitToOrgMember = async (unit, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				empRateUnit: unit
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const addMaxHoursToOrgMember = async (maxHours, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				maxHours: maxHours
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const addEmployeeNuToOrgMember = async (employeeNu, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				employeeNu: employeeNu
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const addStartDateToOrgMember = async (startDate, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				startDate: startDate
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const addDepartmentToOrgMember = async (departmentID, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				departmentID: departmentID
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { createOrganisationMember: dbOrgMember } = data;
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};
const updateOrgMember = async (inputData, orgMemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				...inputData
			};

			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });

			const dbOrgMember = data.createOrganisationMember || data.updateOrganisationMember;
			if (!dbOrgMember) {
				reject(new Error("Unexpected API response structure."));
				return;
			}
			resolve(dbOrgMember);
		} catch (error) {
			reject(error);
		}
	});
};

const addFileToOrgMember = async (file, orgMemId, orgID) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgMemberDetails = {
				id: orgMemId,
				employeeFile: file
				// organisationID: orgID
			};
			const { data } = await API.graphql({ query: updateOrganisationMember, variables: { input: orgMemberDetails } });
			const { updateOrganisationMember: dbOrgMember } = data;

			resolve(dbOrgMember);
		} catch (error) {
			console.error("Error in addFileToOrgMember:", error);
			reject(error);
		}
	});
};
const createInvitation = async (orgMemberID, email) => {
	return new Promise(async (resolve, reject) => {
		try {
			let invitationDetails = {
				orgMemberID: orgMemberID,
				email: email,
				status: "Pending"
			};

			const { data } = await API.graphql({
				query: AWSCreateInvitation,
				variables: { input: invitationDetails }
			});
			const { createInvitation: dvInvitation } = data;

			resolve(dvInvitation);
		} catch (error) {
			reject(error);
		}
	});
};

const removeMember = async (id) => {
	return new Promise(async (resolve, reject) => {
		try {
			await API.graphql({
				query: deleteOrganisationMember,
				variables: { input: { id: id } }
			});
			resolve();
		} catch (error) {
			reject(error);
		}
	});
};

const deleteInvitation = async (id) => {
	return new Promise(async (resolve, reject) => {
		try {
			await API.graphql({
				query: AWSDeleteInvitation,
				variables: { input: { id: id } }
			});
			resolve();
		} catch (error) {
			reject(error);
		}
	});
};

const updateMember = async (id, personID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const details = {
				id: id,
				personID: personID
			};

			const { data } = await API.graphql({
				query: updateOrganisationMember,
				variables: { input: details }
			});
			const { updateOrganisationMember: orgMember } = data;
			const { organisation } = orgMember;

			resolve(organisation);
		} catch (error) {
			reject(error);
		}
	});
};

export const OrganisationContext = createContext({
	organisation: {},
	setOrganisation: () => {}
});

export const SubscriptionContext = createContext({
	subscription: {},
	setSubscription: () => {}
});

export const OrganisationProvider = ({ children }) => {
	const [organisation, setOrganisation] = useState(organisationSchema);

	return <OrganisationContext.Provider value={{ organisation, setOrganisation }}>{children}</OrganisationContext.Provider>;
};

export const SubscriptionProvider = ({ children }) => {
	const [subscription, setSubscription] = useState(subscriptionSchema);

	return <SubscriptionContext.Provider value={{ subscription, setSubscription }}>{children}</SubscriptionContext.Provider>;
};
// Methods to action details about the organisation.
export const orgUtils = {
	loadLogo: (organisation) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { logoURI } = organisation;
				let signedURI;
				if (logoURI) {
					signedURI = await Storage.get(logoURI);
				}
				resolve({
					uri: logoURI,
					signedImageURL: signedURI,
					lastRetrieved: new Date()
				});
			} catch (error) {
				reject(error);
			}
		});
	},
	save: function (details) {
		return new Promise(async (resolve, reject) => {
			try {
				const orgDetails = await saveOrganisation(details);
				resolve({ ...organisationSchema, ...orgDetails });
			} catch (error) {
				reject(error);
			}
		});
	},
	get: function (details) {
		return new Promise(async (resolve, reject) => {
			try {
				const orgDetails = await getOrganisation(details);
				resolve({ ...organisationSchema, ...orgDetails });
			} catch (error) {
				reject(error);
			}
		});
	},
	addMember: function (member, orgId, email, name, id = null, isPayRoll = false, check = true) {
		return new Promise(async (resolve, reject) => {
			try {
				const orgMember = await addMember(member.id, orgId, email, name, id, isPayRoll);
				const { organisation } = orgMember;
				const updatedDbOrg = addExtraFieldsToOrg(organisation);
				let results = {
					orgMember: orgMember,
					person: "",
					organisation: updatedDbOrg
				};

				// This will only be applicable if current user is being added. The admin won't have access to this user so will only happen when adding the org owner right now.
				if (member.id && check) {
					// For now just get the persons profile again as it will have the new org added to the nested data. Could possibly makes this a bit cleaner?
					results.person = await personUtils.getProfile(member);
				}

				resolve(results);
			} catch (error) {
				reject(error);
			}
		});
	},
	createInvitation: function (orgMemberID, email) {
		return new Promise(async (resolve, reject) => {
			try {
				const invitation = await createInvitation(orgMemberID, email);
				resolve(invitation);
			} catch (error) {
				reject(error);
			}
		});
	},
	removeMember: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				await removeMember(id);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteInvitation: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				await deleteInvitation(id);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	updateMember: function (id, person) {
		return new Promise(async (resolve, reject) => {
			try {
				const org = await updateMember(id, person.id);
				resolve(org);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveRole: function (role) {
		return new Promise(async (resolve, reject) => {
			try {
				await saveOrgRole(role);
				const orgDetails = await getOrganisation({ id: role.organisationID });
				resolve(orgDetails);
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteRole: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				await deleteRole(id);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	mapOrgMembers: async (org) => {
		const mappedMembers = await orgUtils.mapMembers(org.members);
		const mappedDeletedMembers = await orgUtils.mapMembers(org.deletedMembers);
		const mappedAllMembers = await orgUtils.mapMembers(org.allMembers);
		return {
			...{ members: mappedMembers },
			...{ deletedMembers: mappedDeletedMembers },
			...{ allMembers: mappedAllMembers }
		};
	},
	mapMembers: async (members) => {
		let newMembers = members?.items ? members.items : members;
		return await Promise.all(
			newMembers
				.filter((item) => item.isPayRoll !== true)
				.map((member) => {
					return new Promise(async (resolve, reject) => {
						try {
							const {
								id,
								email,
								orgUsername,
								person,
								invitations,
								roleID,
								assignedAdmin,
								assignedFinancials,
								assignedRequests,
								assignedSuperAdmin,
								huorlyRate,
								maxHours,
								departmentID,
								status,
								employeeFile,
								employeeNu,
								empRateUnit,
								startDate,
								completedCheckIns,
								leaveRecords,
								disciplinary 
							} = member;

							let newPerson = person;
							let memberId = id;

							if (typeof person === "string") {
								memberId = member.orgUserId;
								newPerson = {
									id: member.id,
									person: member.person,
									firstName: member.firstName,
									lastName: member.lastName,
									username: member.username,
									salary: member.salary,
									employeePhone: member.employeePhone,
									emergencyNo: member.emergencyNo,
									avatarURI: member.avatarURI,
									pin: member.pin,
									organisations: member.organisations,
									createdAt: member.createdAt,
									updatedAt: member.updatedAt,
									owner: member.owner
								};
							}

							let disciplinaryRecords = disciplinary?.items || [];

							let staffMember = {
								orgUserId: memberId,
								email,
								orgUsername,
								invitations,
								roleIDs: roleID,
								assignedAdmin,
								assignedFinancials,
								assignedRequests,
								assignedSuperAdmin,
								status,
								huorlyRate,
								maxHours,
								employeeNu,
								empRateUnit,
								departmentIDs: departmentID || [],
								employeeFiles: employeeFile || [],
								startDate,
								completedCheckIns: completedCheckIns.items,
								leaveRecords: leaveRecords.items,
								disciplinaryRecords: disciplinaryRecords,
								...newPerson
							};

							// Load the member avatar
							const memberAvatar = await personUtils.loadAvatar(staffMember);
							staffMember.avatar = memberAvatar;
							resolve(staffMember);
						} catch (error) {
							reject(error);
						}
					});
				})
		);
	},

	mapReport: async (report, organisationID) => {
		return new Promise(async (resolve, reject) => {
			try {
				let mappedReport = report;

				if (report.length > 99) {
					let months = [];
					for (let i = -1; i < 12; i++) {
						const currentDate = new Date();
						currentDate.setMonth(currentDate.getMonth() - i);
						const year = currentDate.getFullYear();
						const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
						months.push(parseInt(`${year}${month}`));
					}

					Promise.all(
						months.map(async (month) => {
							return await AWSGetReportsByMonth(organisationID, month);
						})
					).then((results) => {
						const mergedReports = [];
						results.forEach((report) => {
							mergedReports.push(...report.items);
						});
						resolve(mergedReports);
					});
				} else {
					resolve(mappedReport);
				}
			} catch (error) {
				reject(error);
			}
		});
	},
	getShifts: async (orgID, startDate, endDate, isAdmin = false, departmentIDs = [], cachedShifts, reload = false) => {
		// if cachedShifts and reload is false, return the cached shifts
		if (cachedShifts && !reload) {
			return cachedShifts;
		}
		return new Promise(async (resolve, reject) => {
			// The endDate will be the very start of the day i.e. 00:00, need to change this to 23:59 to include shifts on this day.
			//const inclusiveEndDate = endDate + 23 * 60 * 60 * 1000 + 59 * 60 * 1000;
			try {
				const shifts = await AWSGetShifts(orgID, startDate, endDate, isAdmin, departmentIDs);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveShift: function (shift) {
		return new Promise(async (resolve, reject) => {
			try {
				const savedShift = await AWSSaveShift(shift);
				resolve(savedShift);
			} catch (error) {
				reject(error);
			}
		});
	},
	publishShift: function (shiftData) {
		return new Promise(async (resolve, reject) => {
			try {
				const savedShift = await AWSPublishShift(shiftData);
				resolve(savedShift);
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteShift: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				await AWSdeleteShift(id);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	swapShift: function (shiftsDetails) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSwapShift(shiftsDetails);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	swapRequest: function (shift) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSwapRequest(shift);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	moveShift: function (shift) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSMoveShift(shift);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	replaceShift: function (shift) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSReplaceShift(shift);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getPreferences: async (orgID, startDate, endDate) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await AWSGetPreferences(orgID, startDate, endDate);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	savePreference: function (preference) {
		return new Promise(async (resolve, reject) => {
			try {
				const savedPreference = await AWSSavePreference(preference);
				resolve(savedPreference);
			} catch (error) {
				reject(error);
			}
		});
	},
	deletePreference: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				await deletePreference(id);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	saveNote: function (note) {
		return new Promise(async (resolve, reject) => {
			try {
				const savedNote = await AWSSaveNote(note);
				resolve(savedNote);
			} catch (error) {
				reject(error);
			}
		});
	},
	getNotesByShifts: async (shiftId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await getNotesByShift(shiftId);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveNoteParticularDay: function (note) {
		return new Promise(async (resolve, reject) => {
			try {
				const savedNote = await AWSSaveNoteParticularDay(note);
				resolve(savedNote);
			} catch (error) {
				reject(error);
			}
		});
	},
	getNoteParticularDay: function (orgID, startDate, endDate) {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await AWSGetNoteParticularDay(orgID, startDate, endDate);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	addRoleToMember: async (roleId, orgID) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await addRoleToOrgMember(roleId, orgID);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	addDepartmentToMember: async (departmentId, orgID) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await addDepartmentToOrgMember(departmentId, orgID);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	updateOrgMember: async (data, orgID) => {
		return new Promise(async (resolve, reject) => {
			try {
				const r = await updateOrgMember(data, orgID);
				resolve(r);
			} catch (error) {
				reject(error);
			}
		});
	},
	addFileToMember: async (fileId, userOrgID, orgID) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await addFileToOrgMember(fileId, userOrgID, orgID);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	assignAsAdminMember: async (isAdmin = false, orgID) => {
		return new Promise(async (resolve, reject) => {
			try {
				await assignAsAdminOrgMember(isAdmin, orgID);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	},
	assignFinancialsForAssignedAdmin: async (allowFinancials = false, orgID) => {
		return new Promise(async (resolve, reject) => {
			try {
				await assignFinancialsForAssignedAdminMember(allowFinancials, orgID);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	},
	assignAsRequestsMember: async (isRequests, orgID) => {
		return new Promise(async (resolve, reject) => {
			try {
				await assignAsRequestsOrgMember(isRequests, orgID);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	},
	assignAsSuperAdmin: async (isSuperAdmin, orgID) => {
		return new Promise(async (resolve, reject) => {
			try {
				await assignAsSuperAdminOrgMember(isSuperAdmin, orgID);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	},
	addHourlyRateToMember: async (rate, orgMemId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await addHourlyRateToOrgMember(rate, orgMemId);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	addRateUnitToMember: async (unit, orgMemId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await addRateUnitToOrgMember(unit, orgMemId);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	addMaxHoursToMember: async (maxHours, orgMemId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await addMaxHoursToOrgMember(maxHours, orgMemId);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	addEmployeeNuToMember: async (rate, orgMemId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await addEmployeeNuToOrgMember(rate, orgMemId);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	addStartDateToMember: async (startDate, orgMemId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const shifts = await addStartDateToOrgMember(startDate, orgMemId);
				resolve(shifts);
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteNote: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				const deleteNote = await AWSDeleteNote(id);
				resolve(deleteNote);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveTemplate: function (templateDetail) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await API.graphql({
					query: createTemplate,
					variables: { input: templateDetail }
				});
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},

	deleteNotePerticularDay: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				const deleteNote = await AWSDestroyNoteParticularDay(id);
				resolve(deleteNote);
			} catch (error) {
				reject(error);
			}
		});
	},
	//getTemplate
	listTemplate: function (orgID, limit = 10) {
		const queryParams = {
			organisationID: orgID,
			limit: limit
		};
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await API.graphql(graphqlOperation(AWSGetOrgTemplates, queryParams));
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	destroyTemplate: function (Id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await API.graphql({
					query: deleteTemplate,
					variables: { input: { id: Id } }
				});
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveDepartment: function (department) {
		return new Promise(async (resolve, reject) => {
			try {
				await saveOrgDepartment(department);
				const orgDetails = await getOrganisation({ id: department.organisationID });
				resolve(orgDetails);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveTimeOff: function (timeOffDetail) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveTimeOff(timeOffDetail);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getTimeOff: function (organisationID, startRange, endRange, isAdmin = null, memberId = null, nextToken = "") {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetTimeOff(organisationID, startRange, endRange, isAdmin, memberId, nextToken);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getTimeOffForMember: function (organisationID, memberId, status) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetMemberTimeOff(organisationID, memberId, status);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	changeStatusTimeOff: function (id, status, note) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSChangeStatusTimeOff(id, status, note);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveShiftSwapRequest: function (shiftSwapData) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveRequest(shiftSwapData);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getMemberShiftSwapRequests: function (orgId, memberID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetMemberRequests(orgId, memberID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getOrgShiftSwapRequests: function (orgID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetOrgRequests(orgID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getFromMemberShiftSwapRequests: function (memberID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetFromMemberRequests(memberID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	destroyTimeOff: function (ID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSDestroyTimeOff(ID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveAllRequest: function (shiftData) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveAllRequest(shiftData);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getAllRequest: function (roleID = [], orgID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetAllRequest(roleID, orgID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveReport: function (report) {
		return new Promise(async (resolve, reject) => {
			try {
				const savedReport = await AWSSaveReport(report);
				resolve(savedReport);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveGiveAwayRequest: function (shiftSwapData) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveGiveAway(shiftSwapData);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getMemberGiveAwayRequests: function (orgId, memberID = null) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetMemberGiveAway(orgId, memberID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getGiveAwayRequestsForOrgMember: function (orgID, memberID = null) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetOrganisationMemberGiveAway(orgID, memberID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getGiveAwayRequestsForOrgMembers: function (orgID, memberIDs = []) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetOrganisationMembersGiveAway(orgID, memberIDs);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	giveAwayShift: function (shift) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGiveAwayShift(shift);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	destroyGiveaway: function (giveAwayID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSDestroyGiveAwayShift(giveAwayID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveAllGiveAway: function (shiftData) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveAllGiveAway(shiftData);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getReport: function (orgID, baseDay) {
		return new Promise(async (resolve, reject) => {
			try {
				const report = await AWSGetReport(orgID, baseDay);
				resolve(report);
			} catch (error) {
				reject(error);
			}
		});
	},
	getWeekReport: function (orgID, startDate, endDate) {
		return new Promise(async (resolve, reject) => {
			try {
				const report = await AWSGetWeekReport(orgID, startDate, endDate);
				resolve(report);
			} catch (error) {
				reject(error);
			}
		});
	},
	dstroyAllRequestByShift: function (shiftId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSDestroyAllRequest(shiftId);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	destroyAllRequest: function (requestId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSDestroyAllMemberSwapShift(requestId);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},

	deleteReport: function (reportId) {
		return new Promise(async (resolve, reject) => {
			try {
				const report = await AWSdestroyReport(reportId);
				resolve(report);
			} catch (error) {
				reject(error);
			}
		});
	},
	getAllGiveAway: function (roleIDs = [], orgID, memberID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetAllGiveAway(roleIDs, orgID, memberID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteDepartment: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				await deleteDepartment(id);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	getAverageOfDay: function (orgID, date, day) {
		return new Promise(async (resolve, reject) => {
			try {
				const report = await AWSGetAverageOfDay(orgID, date, day);
				resolve(report);
			} catch (error) {
				reject(error);
			}
		});
	},
	destroyAllGiveaway: function (giveAwayID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSDestroyAllGiveAwayShift(giveAwayID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	addCheckinCheckouttoShift: function (shiftDetail) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await addClockIn(shiftDetail);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getAllPendingClockIns: function (organisationID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await getPendingClockIns(organisationID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	approveClockIn: function (shiftDetail) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await approvePendingClockIn(shiftDetail);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	denyClockIn: function (shiftDetail) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await denyPendingClockIn(shiftDetail);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getShiftOnDayOfMember: function (baseDay, organisationID, memberID, sID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetShiftOnDayOfMember(baseDay, organisationID, memberID, sID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveMessage: function (message) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveMessage(message);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteMessage: function (messageId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSDeleteAllMessage(messageId);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getMessage: function (orgId, memberID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await getOrganisationMessage(orgId, memberID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getRoleMessage: function (orgId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetOrgRoleMessage(orgId);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveRoleMessage: function (roleMessage) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveRoleMessage(roleMessage);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	cancelSubscription: function (subId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await cancelSubscriptionApi(subId);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	updateSubscription: function (subId, quantity) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await updateSubscriptionApi(subId, quantity);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveNotice: function (notice) {
		return new Promise(async (resolve, reject) => {
			try {
				const savedNotice = await AWSSaveNoitice(notice);
				resolve(savedNotice);
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
	},
	getNotice: function (orgID) {
		return new Promise(async (resolve, reject) => {
			try {
				const notices = await AWSGetNoitice(orgID);
				resolve(notices);
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
	},
	deleteNotice: function (noticeId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSDeleteNotice(noticeId);
				resolve(data);
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
	},
	deleteNoticeComment: function (commentId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSDeleteNoticeComment(commentId);
				resolve(data);
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
	},
	addCommentOnNotice: function (commentData) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSAddComment(commentData);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getDeptMessage: function (orgId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetOrgDeptMessage(orgId);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveDeptMessage: function (deptMessage) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveDeptMessage(deptMessage);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getOrgMessage: function (orgId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetOrgGroupMessage(orgId);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveOrgMessage: function (orgMessage) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveOrgGroupMessage(orgMessage);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	readIndividualMessage: function (message) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSreadMessage(message);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	unreadIndividualMessages: function (orgID, fromMemberID, roleIDs, deptIds) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSunReadMessages(orgID, fromMemberID, roleIDs, deptIds);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	readGroupMessage: function (type, memberIDs, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSReadGroupMessage(type, memberIDs, id);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getStock: function (organisationID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetStock(organisationID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveStock: function (stockDetails) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSSaveStock(stockDetails);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteStock: function (stockId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSDeleteStock(stockId);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	changeWorkingDayAndMember: function (shiftDetail) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await changeBaseDayAndMember(shiftDetail);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	memberShiftByWeek: function (orgID, memberID, weekStartDay, shiftDates) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await getMemberWeekShift(orgID, memberID, weekStartDay, shiftDates);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	storeFile: function (fileDetail) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await saveFile(fileDetail);
				resolve(data);
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
	},
	sendReportEmail: function (data) {
		return new Promise(async (resolve, reject) => {
			try {
				const myHeaders = new Headers();
				myHeaders.append("Content-Type", "application/json");
				const raw = JSON.stringify(data);
				const requestOptions = {
					method: "POST",
					headers: myHeaders,
					body: raw,
					redirect: "follow"
				};
				await fetch("https://hndhdl6hud.execute-api.eu-west-1.amazonaws.com/master/report", requestOptions)
					.then((response) => response.text())
					.then((result) => resolve({ result }))
					.catch((error) => reject(error));
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
	},
	getCurrentYearShifts: function (orgID, endDate) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetCurrentYearShifts(orgID, endDate);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	// getShiftsForWeek: function (orgID, startDay) {
	// 	return new Promise(async (resolve, reject) => {
	// 		try {
	// 			const data = await AWSGetShiftsForTheWeek(orgID, startDay);
	// 			resolve(data);
	// 		} catch (error) {
	// 			reject(error);
	// 		}
	// 	});
	// },
	getMemberYearShifts: function (orgID, memberId, endDate) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetMemberYearShifts(orgID, memberId, endDate);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getMemberYearShiftsByMonth: function (orgID, memberId, endDate) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetMemberYearShiftsByMonth(orgID, memberId, endDate);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getStoredFiles: function (organisationID) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await getOrganisationFiles(organisationID);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	createSessionId: function (params) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await createSessionApi(params);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveShiftTemplate: function (shiftTemplate) {
		return new Promise(async (resolve, reject) => {
			try {
				const savedTemplate = await AWSSaveShiftTemplate(shiftTemplate);
				resolve(savedTemplate);
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteFile: function (fileId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await deleteFileById(fileId);
				resolve(data);
			} catch (error) {
				console.log(error);
			}
		});
	},
	storeFolder: function (folderDetail) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await saveFolder(folderDetail);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveExtraPayDay: function (detail) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await saveExtraPayDay(detail);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getPaidTimeOff: function (orgID, endDate) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await AWSGetPaidTimeOff(orgID, endDate);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getShiftTemplates: function (orgID) {
		return new Promise(async (resolve, reject) => {
			try {
				const shiftTemplates = await AWSGetShiftTemplates(orgID);
				resolve(shiftTemplates);
			} catch (error) {
				reject(error);
			}
		});
	},
	getExtraPayDay: function (orgID, start, end) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await getExtraPayDay(orgID, start, end);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getClockMember: function (orgID) {
		return new Promise(async (resolve, reject) => {
			try {
				const queryParams = {
					organisationID: orgID,
					filter: { isPayRoll: { eq: true } }
				};
				const {
					data: { getMemberByOrgId: data }
				} = await API.graphql(graphqlOperation(getMemberByOrgId, queryParams));
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteShiftTemplate: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				const deleteNote = await destroyShiftTemplate(id);
				resolve(deleteNote);
			} catch (error) {
				reject(error);
			}
		});
	},
	getOrgMembers: function (orgID) {
		return new Promise(async (resolve, reject) => {
			try {
				const queryParams = {
					organisationID: orgID
				};
				const {
					data: { getMemberByOrgId: data }
				} = await API.graphql(graphqlOperation(getMemberByOrgId, queryParams));
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getStoredFolders: function (organisationID, type = "public") {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await getOrganisationFolders(organisationID, type);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getStoredFolderByFilter: function (organisationID, filter, type = "public") {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await getFolderByFilter(organisationID, filter, type);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	savePunchOfMember: function (punchData) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await savePunch(punchData);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	deletePunch: function (id) {
		return new Promise(async (resolve, reject) => {
			try {
				await deletePunch(id);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteFolder: function (folderId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await deleteFolderById(folderId);
				resolve(data);
			} catch (error) {
				console.log(error);
			}
		});
	},
	getPunchOfDay: function (orgId, dateEpoch) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await getDayPunches(orgId, dateEpoch);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getPunchBetweenDay: function (orgID, start, end, cachedPunches, reload = false) {
		// if cachedPunches and reload is false, return the cached punches
		if (cachedPunches && !reload) {
			return { items: cachedPunches };
		}
		return new Promise((resolve, reject) => {
			try {
				const data = getDayPunchesBetweenDates(orgID, start, end);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getDayShifts: function (orgID, baseDay) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await getDayShifts(orgID, baseDay);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveCheckIn: function (checkIn) {
		return new Promise(async (resolve, reject) => {
			try {
				await saveOrgCheckIn(checkIn);
				const orgDetails = await getOrganisation({ id: checkIn.organisationID });
				resolve(orgDetails);
			} catch (error) {
				reject(error);
			}
		});
	},
	deleteCheckIn: function (checkInId) {
		return new Promise(async (resolve, reject) => {
			try {
				await deleteCheckIn(checkInId);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	saveCompletedCheckIn: function (completedCheckIn) {
		return new Promise(async (resolve, reject) => {
			try {
				await saveCompletedCheckIn(completedCheckIn);
				const orgDetails = await getOrganisation({ id: completedCheckIn.organisationID });
				resolve(orgDetails);
			} catch (error) {
				reject(error);
			}
		});
	},
	addLeaveOption: function (leaveOption) {
		return new Promise(async (resolve, reject) => {
			try {
				await saveLeaveOption(leaveOption);
				const orgDetails = await getOrganisation({ id: leaveOption.organisationID });
				resolve(orgDetails);
			} catch (error) {
				reject(error);
			}
		});
	},
	removeLeaveOption: function (leaveOptionId) {
		return new Promise(async (resolve, reject) => {
			try {
				await deleteLeaveOption(leaveOptionId);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	addLeaveRecord: function (leaveRecord) {
		return new Promise(async (resolve, reject) => {
			try {
				await saveLeaveRecord(leaveRecord);
				const orgDetails = await getOrganisation({ id: leaveRecord.organisationID });
				resolve(orgDetails);
			} catch (error) {
				reject(error);
			}
		});
	},
	removeLeaveRecord: function (leaveRecordId) {
		return new Promise(async (resolve, reject) => {
			try {
				await deleteLeaveRecord(leaveRecordId);
				resolve({});
			} catch (error) {
				reject(error);
			}
		});
	},
	createDisciplinaryRecord: function (disciplinaryData) {
		return new Promise(async (resolve, reject) => {
			try {
				console.log("Creating disciplinary record with data:", disciplinaryData);

				const { data } = await API.graphql({
					query: AWSCreateDisciplinaryRecord,
					variables: { input: disciplinaryData }
				});

				console.log("Disciplinary record created successfully:", data.createDisciplinaryRecord);

				resolve(data.createDisciplinaryRecord);
			} catch (error) {
				console.error("Error creating disciplinary record:", error);
				reject(error);
			}
		});
	},

	deleteDisciplinaryRecord: function (disciplinaryRecordID) {
		return new Promise(async (resolve, reject) => {
			try {
				await API.graphql({
					query: AWSDeleteDisciplinaryRecord,
					variables: { input: { id: disciplinaryRecordID } }
				});
				resolve();
			} catch (error) {
				console.error("Error deleting disciplinary record:", error);
				reject(error);
			}
		});
	}
};
