import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { dateMeta, nth } from "ww-framework";
import { CalendarDataContext } from "ww-stores";
import { getSignedUrl } from "../files";

const ImagesList = ({ memberID }) => {
	const { shift, punchData } = useContext(CalendarDataContext);
	const memberShifts = shift.filter((s) => s.memberID === memberID);
	const memberPunchData = punchData.filter((p) => memberShifts.map((s) => s.id).includes(p.shiftID));
	return (
		<div className="overflow-y-scroll h-full pl-3 bg-darkww-800 shadow-lg rounded-lg mt-1 ml-4 p-3">
			<div className="mt-1 flex flex-col items-center justify-top h-full">
				<h1 className="text-xl font-bold text-white">Image References</h1>
				{memberPunchData.map((punch) => {
					const shift = memberShifts.find((s) => s.id === punch.shiftID);
					const shiftDay = dateMeta(new Date(shift?.baseDay * 1000));
					return (
						<div key={punch.id} className=" mt-4 px-4 py-2 bg-gray-100 rounded-lg">
							<div className="font-bold text-gray-700 text-xl pb-2">{`${shiftDay?.dayDesc},  ${shiftDay?.monthShortDesc} ${
								shiftDay?.day
							}${nth(shiftDay?.day)}`}</div>
							<PunchImages punch={punch} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

function PunchImages({ punch }) {
	const [imageData, setImageData] = useState([]);

	useEffect(() => {
		const fetchImages = async () => {
			const images = await Promise.all(
				punch.imageReferences.map(async (image) => {
					const [type, time, path] = image.split(":");
					const signedUrl = await getSignedUrl(path);
					return { type, time, signedUrl, path };
				})
			);
			setImageData(images);
		};

		fetchImages();
	}, [punch.imageReferences]);
	return (
		<div key={punch.id} className="grid grid-cols-2 gap-4 w-full">
			{imageData.map((image, index) => {
				// Convert the timestamp to hours and minutes
				const date = new Date(parseInt(image.time) * 1000); // Convert seconds to milliseconds
				const formattedTime = date.toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit"
				});
 				const capitalizedType = image.type.charAt(0).toUpperCase() + image.type.slice(1);

				return (
					<div key={index} className="flex flex-col items-center justify-center">
						<img src={image.signedUrl} alt="punch" className="h-48 w-48 object-cover rounded-lg" />
						<p className="mt-2 text-sm text-gray-800">
							{capitalizedType} : {formattedTime}
						</p>
					</div>
				);
			})}
		</div>
	);
}

const ExportViewPunchImages = ({ open, setOpen, memberId }) => {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="fixed z-20 inset-0 overflow-hidden" onClose={setOpen}>
				<div className="absolute inset-0 overflow-hidden">
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="absolute z-20 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-y-0 right-0 max-w-full flex z-20 my-2">
						<Transition.Child
							as={Fragment}
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enterFrom="translate-x-full"
							enterTo="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leaveFrom="translate-x-0"
							leaveTo="translate-x-full"
						>
							<div style={{ width: 500 }} className="relative px-5">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-500"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-500"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-1 right-8 -ml-8 pt-2 pr- flex sm:-ml-10 sm:pr-4">
										<button
											type="button"
											className="rounded-md text-gray-300 hover:text-white focus:outline-none"
											onClick={() => setOpen(false)}
										>
											<span className="sr-only">Close panel</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								<ImagesList memberID={memberId} />
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default ExportViewPunchImages;
