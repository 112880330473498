import React, { useContext, useState } from "react";
import { Button, CheckBox, ConfirmModal, Form, InputField, LinkButton, TextArea, Yup, showFailure, showSuccess, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils } from "ww-stores";

const shiftSchema = Yup.object().shape({
  takings: Yup.number().typeError("Number only").nullable().notRequired(),
  target: Yup.number().typeError("Please enter Number only").nullable().notRequired(),
  till: Yup.number().typeError("Number only").nullable().notRequired(),
  note: Yup.string().nullable()
});

const ReportForm = ({ record, activeDay, cancelCB, rebuildCalendar, average, setOrg, hideFinancials = false }) => {
	const { organisation } = useContext(OrganisationContext);
	const [reportSaving, setReportSaving] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const { setMessage } = useSimpleMessage();
	const [open, setOpen] = useState(false);
	const initValues = record ? { ...record } : { takings: null, target: null, till: null, note: null };

	const deleteReport = async (reportId) => {
		try {
			await orgUtils.deleteReport(reportId);
			await setOrg();
			rebuildCalendar(activeDay.date);
			cancelCB();
			setMessage(showSuccess({ title: "Report deleted successfully." }));
		} catch (error) {
			setMessage(showFailure({ title: "Unable to remove Report.", subTitle: error.message }));
		}
	};

	const handlerModal = (e, type) => {
		e.preventDefault();
		setOpen(false);
	};

	return (
		<>
			<ConfirmModal
				handlerModal={handlerModal}
				open={open}
				className="text-base font-medium text-gray-900"
				title="This employee has booked this day off"
				subTitle="Are you sure?"
				confirm="Assign this Employee"
				cancel="Choose another "
			/>
			<Form
				validationSchema={shiftSchema}
				enableReinitialize={true}
				disabled={reportSaving}
				initialValues={initValues}
				onSubmit={async (data, { setSubmitting }) => {
					if (reportSaving) {
						return;
					}

					setReportSaving(true);
					setSubmitting(true);

					try {
						const reportData = {
							...data,
							organisationID: organisation.id,
							reportDate: activeDay.baseEpoch,
							reportDay: activeDay.dayDesc.toUpperCase() // Ensure consistent case
						};

						// Fetch existing report
						const existingReportResponse = await orgUtils.getReport(organisation.id, activeDay.baseEpoch);
						const existingReport = existingReportResponse.items.length > 0 ? existingReportResponse.items[0] : null;

						if (existingReport && !record?.id) {
							// If there's an existing report and we're not editing an existing record, prevent save
							setMessage(showFailure({ title: "A report for this day already exists." }));
						} else {
							// Save or update report
							if (record?.id) {
								// Update existing report
								reportData.id = record.id;
							}
							await orgUtils.saveReport(reportData);

							cancelCB();
							await setOrg();
							setMessage(showSuccess({ title: "Report saved successfully." }));
							rebuildCalendar(activeDay.date);
						}
					} catch (error) {
						setMessage(
							showFailure({
								title: "Unable to save Report.",
								subTitle: error.message
							})
						);
					} finally {
						setReportSaving(false);
						setSubmitting(false);
					}
				}}
			>
				<div className="w-72 flex flex-col">
					{!hideFinancials && (
						<div className="my-2 w-5/6">
							<InputField label="Today's Target" name="target" labelTextColour="text-white" placeholder="Today's Target" />
						</div>
					)}
					<div className="flex justify-center">
						<div className="my-2 w-5/6">
							<InputField
								label={`Takings ${!hideFinancials && average?.takings > 0 ? "(avg €" + Math.round(average?.takings) + ")" : ""}`}
								name="takings"
								labelTextColour="text-white"
								placeholder={hideFinancials ? "Enter Takings" : `${average?.takings > 0 ? average?.takings : "Enter Takings"}`}
							/>
						</div>
						<div className="text-gray-700 xl:text-white px-2 self-center" />
						<div className="mt-2">
							<InputField label="Till +/-" name="till" labelTextColour="text-white" placeholder="+/- Till?" />
						</div>
					</div>

					{/* Food and Drink Fields with Average Display */}
					<div className="flex justify-center">
						<div className="my-2 w-5/6">
							<InputField
								label={`Food ${!hideFinancials && average?.food > 0 ? "(avg €" + Math.round(average?.food) + ")" : ""}`}
								name="food"
								labelTextColour="text-white"
								placeholder={hideFinancials ? "Total Food" : `${average?.food > 0 ? average?.food : "Total Food"}`}
							/>
						</div>
						<div className="text-gray-700 xl:text-white px-2 self-center" />
						<div className="mt-2">
							<InputField
								label={`Drink ${!hideFinancials && average?.drinks > 0 ? "(avg €" + Math.round(average?.drinks) + ")" : ""}`}
								name="drinks"
								labelTextColour="text-white"
								placeholder={hideFinancials ? "Total Drink" : `${average?.drinks > 0 ? average?.drinks : "Total Drink"}`}
							/>
						</div>
					</div>

					<div className="mt-3">
						<TextArea label="Note" name="note" labelTextColour="text-white" placeholder="Anything to report from today?" />
					</div>
					<div className="mt-5 flex flex-row justify-between">
						<div className="py-2">
							{record?.id && (
								<CheckBox
									name="delete"
									label="Delete"
									labelTextColour="text-white"
									onChange={() => setShowDelete(!showDelete)}
									checked={showDelete}
								/>
							)}
						</div>
						<div className="flex justify-end gap-5">
							{!showDelete ? (
								<>
									<LinkButton label="Cancel" className=" text-white font-semibold hover:text-gray-400" onClick={cancelCB} />
									<Button type="submit" label="Save" disabled={reportSaving} />
								</>
							) : (
								<>
									<LinkButton label="Cancel" className=" text-white font-semibold hover:text-gray-400" onClick={() => setShowDelete(!showDelete)} />
									<Button
										type="button"
										label="Confirm"
										onClick={() => deleteReport(record?.id)}
										colors="bg-red-500 hover:bg-red-600 focus:ring-red-600"
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};

export default ReportForm;
