import { arrayDiff, dateMeta, showFailure } from "ww-framework";
import { orgUtils } from "ww-stores";

export const getOtherLocationShifts = async ({ orgMembers, loggedInPerson, startDate, endDate }) => {
	if (loggedInPerson.organisations.items.length > 0) {
		const allOtherOrganisations = loggedInPerson.organisations.items
			.filter((k) => k.organisationID !== loggedInPerson.currentOrganisation.id)
			.map((orgmember) => orgmember.organisation);
		const shiftsInOtherLocations = await Promise.all(
			allOtherOrganisations.map(async (org) => {
				try {
					const membersWithEmails = orgMembers.filter((member) => member.email);
					let data = await orgUtils.getShifts(org.id, startDate, endDate, true);
					// Filter out shifts where the user has no email
					data = data.filter((shift) => {
						const shiftMember = membersWithEmails.find((member) => member.email === shift?.member?.email);
						return shiftMember !== undefined;
					});
					return data;
				} catch (err) {
					console.error("Error in getOtherLocationShifts:", err);
					return [];
				}
			})
		);
		return shiftsInOtherLocations.flat();
	}
	return [];
};

export const isMemberAlreadyRostered = async ({ member, day, loggedInPerson, selectedWeekShifts, orgMembers, setMessage, currentShiftId }) => {
	const otherLocationShifts = await getOtherLocationShifts({
		orgMembers: orgMembers,
		loggedInPerson,
		startDate: day?.baseEpoch,
		endDate: day?.baseEpoch + 86340 // to the baseEpoch, add 23 hours, 59 minutes to capture the whole day
	});
	const foundMemberShiftsOnDay = selectedWeekShifts.find((k) => {
		const isSameDay = k.baseDay === day.baseEpoch;
		const isSameMember = k?.member?.email === member?.email && k?.member?.id === member?.orgUserId;
		const isDifferentShift = k.id !== currentShiftId;
		const matches = isSameDay && isSameMember && isDifferentShift;

		return matches;
	});
	const foundOtherLocationShiftOnDay = otherLocationShifts.find(
		(k) => k.baseDay === day.baseEpoch && k?.member?.email === member?.email && k?.member?.id === member?.orgUserId
	);
	if (foundMemberShiftsOnDay) {
		setMessage(
			showFailure({
				title: "Unable to save Shift.",
				subTitle: "This employee is already rostered that day."
			})
		);
		return true;
	}
	// Check if a shift is found in other locations' shifts on the same day
	if (foundOtherLocationShiftOnDay) {
		const orgID = foundOtherLocationShiftOnDay?.organisationID;
		const org = loggedInPerson.organisations.items.find((k) => k.organisationID === orgID)?.organisation;
		setMessage(
			showFailure({
				title: "Unable to save Shift.",
				subTitle: `This employee is already rostered in ${org?.name} on the same day.`
			})
		);
		return true;
	}

	return false;
};

export const notifyNotClockedOut = async ({ punches, shifts, lateClockOutNotificationHours }) => {
	let validPunches = punches.filter((p) => p.in !== null);
	let punchesNotClockedOut = validPunches.filter((p) => p.out === null);
	// for each of the punches not clocked out, check if there is another punch for the same shift that is clocked out.
	// if so, we remove the punch from the list
	punchesNotClockedOut = punchesNotClockedOut.filter((p) => {
		const shift = shifts.find((s) => s.id === p.shiftID);
		if (shift) {
			const punchesForShift = validPunches.filter((p) => p.shiftID === shift.id);
			const punchWithBoth = punchesForShift.find((p) => p.in !== null && p.out !== null);
			if (punchWithBoth) {
				return false;
			}
		}
		return true;
	});
	let shiftsNotClockedOut = [];
	punchesNotClockedOut.forEach((p) => {
		let shift = shifts.find((s) => s.id === p.shiftID);
		if (shift) {
			// if the shift is more than lateClockOutNotificationHours/12 hours past the shift end time
			const lateNotificationAfter = (lateClockOutNotificationHours ?? 12) * 3600;
			if (new Date().getTime() / 1000 - shift.shiftEnd > lateNotificationAfter) {
				shiftsNotClockedOut.push(shift);
			}
		}
	});
	if (shiftsNotClockedOut.length > 0) {
		return { shiftsNotClockedOut, punchesNotClockedOut };
	}
	return null;
};

export function getTimeOffData(_timeOff, _currentDay) {
	let _timeOffData = _timeOff.filter((k) => k.fromDate <= _currentDay.baseEpoch && k.toDate >= _currentDay.baseEpoch);
	let filteredTimeOffData = [..._timeOffData];

	_timeOffData.forEach((k) => {
		if (k.repeatDay) {
			if (_currentDay.dayDesc !== k.repeatDay) {
				filteredTimeOffData = filteredTimeOffData.filter((i) => i.id !== k.id);
			}
		}
	});
	_timeOffData = filteredTimeOffData;
	return _timeOffData;
}

export function notifyEarlyClockIn({ punches, shifts, earlyClockInNotificationHours }) {
	let validPunches = punches.filter((p) => p.in !== null);
	// a shift is clocked in early if the punch in time is more than earlyClockInNotificationHours or 30 minutes before the shift start time
	let shiftsClockedInEarly = [];
	let punchesClockedInEarly = [];
	validPunches.forEach((p) => {
		let shift = shifts.find((s) => s.id === p.shiftID);
		if (shift) {
			const earlyNotificationBefore = (earlyClockInNotificationHours ?? 0.5) * 60 * 60;
			if (p.in < shift.shiftStart - earlyNotificationBefore) {
				if (p.status !== "APPROVED") {
					shiftsClockedInEarly.push(shift);
					punchesClockedInEarly.push(p);
				}
			}
		}
	});
	if (shiftsClockedInEarly.length > 0) {
		return { shiftsClockedInEarly, punchesClockedInEarly };
	}
	return null;
}

export function filterShifts({ organisation, person, member, shifts, filterByDepartment }) {
	let filteredShifts = shifts;
	if (organisation?.departments && Array.isArray(organisation?.departments) && organisation?.departments?.length > 0) {
		if (!person?.isAdmin) {
			filteredShifts = shifts.filter((s) =>
				s.member?.departmentID !== null && member?.departmentIDs.length > 0
					? arrayDiff(s.member?.departmentID || [], member?.departmentIDs)
					: s?.memberID === member?.orgUserId
			);
		}
		if ((person?.isAdmin || person?.assignedAdmin) && filterByDepartment !== "ALL") {
			filteredShifts = shifts.filter((s) => {
				if (s?.member?.departmentID === null) return false;
				else if (s?.member?.departmentID.includes(filterByDepartment)) return true;
				else return false;
			});
		}
	}
	return filteredShifts;
}

export function computeDaysWithShifts(days, orgReport, allUnfilteredShift, shifts, punches, organisation) {
	let toSetInState = [];
	let validRosterDays = [];

	const daysWithShifts = days.map((calDay) => {
		let previous = orgReport?.filter((k) => k.reportDay === calDay?.dayDesc?.toUpperCase() && k.reportDate < calDay.baseEpoch);
		previous = previous.filter((k) => k.takings !== null);
		let average =
			previous.length > 0
				? previous.reduce(
						(p, c) => {
							p.takings += c?.takings ?? 0;
							p.target += c?.target ?? 0;
							p.food += c?.food ?? 0;
							p.drinks += c?.drinks ?? 0;
							return p;
						},
						{ takings: 0, target: 0, food: 0, drinks: 0 }
				  )
				: 0;

		for (const [key, value] of Object.entries(average)) {
			average[key] = (value / previous.length).toFixed(2);
		}
		let previousWeek = [];
		let report = orgReport?.filter((k) => {
			if (k.reportDay === calDay?.dayDesc?.toUpperCase() && k.reportDate === calDay.baseEpoch - 604800) previousWeek.push(k);
			return k.reportDay === calDay?.dayDesc?.toUpperCase() && k.reportDate === calDay.baseEpoch;
		});
		const { baseEpoch } = calDay;
		const foundAllUnfilteredShift = allUnfilteredShift.filter((shift) => shift.baseDay === baseEpoch);
		const shiftsFound = shifts.filter((shift) => shift.baseDay === baseEpoch);
		let hoursAndEarnings = shiftsFound.reduce(
			(previousTotal, currentShift) => {
				let h =
					(currentShift.shiftEnd -
						currentShift.shiftStart -
						(currentShift.unPaidBreak &&
							(currentShift.breakDuration ? currentShift.breakDuration * 60 : currentShift.breakEnd - currentShift.breakStart))) /
					3600;
				previousTotal.e +=
					currentShift?.member?.empRateUnit === "YEARLY"
						? currentShift?.member?.huorlyRate / 260
						: h * currentShift?.member?.huorlyRate || 0;
				previousTotal.h += h;
				return previousTotal;
			},
			{ e: 0, h: 0 }
		);
		let actualClockedHoursAndEarnings = punches.reduce(
			(previousTotal, currentPunch) => {
				let currentShift = shiftsFound.find((s) => s.id === currentPunch.shiftID);
				if (!currentShift || !currentPunch.in) {
					return previousTotal;
				}
				const isSalaried = currentShift?.member?.empRateUnit === "YEARLY";
				const clockIn = currentPunch.in;
				const clockOut = currentPunch.out ?? Math.floor(new Date().getTime() / 1000 - new Date().getTimezoneOffset() * 60); // Use current time if no clock-out
				const adjustedClockOut = clockOut < clockIn ? clockOut + 86400 : clockOut; // Adjust for midnight crossing

				let h =
					isSalaried || currentShift?.member?.empRateUnit === "YEARLY"
						? 1
						: (adjustedClockOut -
								clockIn -
								(currentShift?.unPaidBreak
									? currentShift?.breakDuration
										? currentShift?.breakDuration * 60
										: currentShift?.breakEnd - currentShift?.breakStart
									: 0)) /
						  3600;
				previousTotal.e += isSalaried ? currentShift?.member?.huorlyRate / 260 : h * currentShift?.member?.huorlyRate || 0;
				previousTotal.h += h;
				return previousTotal;
			},
			{ e: 0, h: 0 }
		);
		toSetInState.push({
			calculation: report.length
				? {
						actual: (hoursAndEarnings.e / report[0]?.takings) * 100,
						food:
							(hoursAndEarnings.e /
								(organisation.vat === true && organisation.foodPercentage
									? report?.[0]?.food - report?.[0]?.food * (organisation.foodPercentage / 100)
									: report?.[0]?.food)) *
							100,
						drinks:
							(hoursAndEarnings.e /
								(organisation.vat === true && organisation.drinkPercentage
									? report?.[0]?.drinks - report?.[0]?.drinks * (organisation.drinkPercentage / 100)
									: report?.[0]?.drinks)) *
							100
				  }
				: { actual: 0, food: 0, drinks: 0 },
			d: hoursAndEarnings,
			ad: actualClockedHoursAndEarnings
		});
		// Only add to validRosterDays if there's a valid report with actual costs
		if (report.length > 0 && report[0].takings !== null) {
			validRosterDays.push(baseEpoch);
		}

		return {
			...calDay,
			...{ shifts: shiftsFound },
			...{ allUnfilteredShift: foundAllUnfilteredShift },
			...{ average: average },
			...{ report: report },
			...{ previousWeekReport: previousWeek }
		};
	});

	return { daysWithShifts, toSetInState, validRosterDays };
}

export const calculateTotalTime = (shiftArray) => {
	return shiftArray.reduce((previousTotal, currentShift) => {
		const { shiftStart, shiftEnd, unPaidBreak, breakDuration, breakStart, breakEnd } = currentShift;
		let adjustedShiftEnd = shiftEnd;

		// Check if the shift ends after midnight (i.e., the next day)
		if (shiftEnd < shiftStart) {
			adjustedShiftEnd = shiftEnd + 24 * 3600; // Add 24 hours in seconds
		}

		const shiftDuration = adjustedShiftEnd - shiftStart;
		const breakTime = unPaidBreak ? (breakDuration ? breakDuration * 60 : breakEnd - breakStart) : 0;

		return previousTotal + (shiftDuration - breakTime) / 3600;
	}, 0);
};
const calculateTimeOffEarnings = (timeOffData, paidTimeOff, filterByDepartment) => {
	let timeOffEarnings = 0;
	if (paidTimeOff && timeOffData) {
		timeOffEarnings = timeOffData.reduce((previousTotal, timeOff) => {
			if (!timeOff.isPaid) {
				return previousTotal;
			}
			timeOffData?.forEach((timeOff) => {
				if (
					filterByDepartment !== "ALL" &&
					(!timeOff?.member?.departmentID || !timeOff?.member?.departmentID?.includes(filterByDepartment))
				) {
				} else {
					return previousTotal;
				}
			});
			// Calculate rate
			let rate = timeOff?.member?.empRateUnit === "YEARLY" ? timeOff?.member?.huorlyRate / 260 : timeOff?.member?.huorlyRate;
			let earningsForTimeOff = rate * (timeOff?.member?.empRateUnit === "YEARLY" ? 1 : 7.5);

			// Adjust for multiple departments
			const numDepartments = timeOff?.member?.departmentID?.length || 1;
			if (numDepartments > 1) {
				earningsForTimeOff /= numDepartments;
			}
			return previousTotal + earningsForTimeOff;
		}, 0);
	}
	return timeOffEarnings;
};
export const calculateTotalEarnings = (shiftArray, extraPay = 0, timeOffData, paidTimeOff, filterByDepartment, live = false) => {
	const timeOffEarnings = calculateTimeOffEarnings(timeOffData, paidTimeOff, filterByDepartment);
	// Calculate shift earnings
	let shiftEarnings = shiftArray
		.filter((shift) => shift.member)
		.reduce((previousTotal, currentShift) => {
			const isSalaried = currentShift?.member?.empRateUnit === "YEARLY";
			const shiftStart = currentShift?.shiftStart;
			let shiftEnd = live && currentShift?.shiftEnd > new Date().getTime() / 1000 ? new Date().getTime() / 1000 : currentShift?.shiftEnd;
			if (shiftEnd < shiftStart) {
				shiftEnd += 24 * 3600; // Add 24 hours in seconds
			}

			let rate = isSalaried ? currentShift?.member?.huorlyRate / 260 : currentShift?.member?.huorlyRate;

			if (!rate) {
				return previousTotal;
			}

			if (extraPay) {
				rate *= 1 + extraPay / 100;
			}

			const hoursWorked =
				currentShift?.member?.empRateUnit === "YEARLY"
					? 1
					: (shiftEnd -
							shiftStart -
							(currentShift?.unPaidBreak
								? currentShift?.breakDuration
									? currentShift?.breakDuration * 60
									: currentShift?.breakEnd - currentShift?.breakStart
								: 0)) /
					  3600;

			const earnings = hoursWorked * rate;
			return previousTotal + earnings;
		}, 0);
	return timeOffEarnings + shiftEarnings;
};

export const calculateLiveActualEarnings = (shiftArray, punchesArray, extraPay = 0, timeOffData, paidTimeOff, filterByDepartment) => {
	// calculates the earnings for given shift that were actually clocked in
	const timeOffEarnings = calculateTimeOffEarnings(timeOffData, paidTimeOff, filterByDepartment);
	
	const attendedShiftEarning = punchesArray.reduce((previousTotal, currentPunch) => {
		if (!currentPunch.in) return previousTotal;

		const currentShift = shiftArray.find((shift) => shift.id === currentPunch.shiftID);
		if (!currentShift) return previousTotal;

		const isSalaried = currentShift.member?.empRateUnit === "YEARLY";
		const clockIn = currentPunch.in;
		const clockOut = currentPunch.out ?? Date.now() / 1000 - new Date().getTimezoneOffset() * 60; // if it's not yet clocked out, use current time
		// Handle punches crossing midnight
		const adjustedClockOut = clockOut < clockIn ? clockOut + 86400 : clockOut;
		let rate = currentShift.member?.huorlyRate;

		if (!rate) return previousTotal;

		// for salaried employees, for rate, we consider a whole day's pay, regardless of the hours worked
		if (isSalaried) {
			rate /= 260;
		} else if (extraPay) {
			rate *= 1 + extraPay / 100;
		}

		const breakTime = currentShift?.unPaidBreak
			? currentShift.breakDuration
				? currentShift.breakDuration * 60
				: currentShift.breakEnd - currentShift.breakStart
			: 0;
		const actualHoursWorked = isSalaried ? 1 : (adjustedClockOut - clockIn - breakTime) / 3600;

		const earnings = actualHoursWorked * rate;
		return previousTotal + earnings;
	}, 0);

	return timeOffEarnings + attendedShiftEarning;
};

export const calculateLiveRosteredCost = (shiftArray, extraPay = 0, timeOffData, paidTimeOff, filterByDepartment) => {
	const timeOffEarnings = calculateTimeOffEarnings(timeOffData, paidTimeOff, filterByDepartment);
	let shiftEarnings = shiftArray
		.filter((shift) => shift.member)
		.reduce((previousTotal, currentShift) => {
			const isSalaried = currentShift?.member?.empRateUnit === "YEARLY";
			const shiftStart = currentShift?.shiftStart;
			const currentTime = new Date().getTime() / 1000;
			const adjustedCurrentTime = currentTime - new Date().getTimezoneOffset() * 60;
			const shiftEnd = currentShift?.shiftEnd > adjustedCurrentTime ? adjustedCurrentTime : currentShift?.shiftEnd;

			// Handle shifts crossing midnight
			const adjustedShiftEnd = shiftEnd < shiftStart ? shiftEnd + 86400 : shiftEnd;

			let rate = isSalaried ? currentShift?.member?.huorlyRate / 260 : currentShift?.member?.huorlyRate;
			const shiftNotStarted = shiftStart > new Date().getTime() / 1000;
			if (!rate || shiftNotStarted) {
				return previousTotal;
			}
			if (extraPay) {
				rate *= 1 + extraPay / 100;
			}

			let actualHoursElapsed = 0;
			if (isSalaried) {
				actualHoursElapsed = 1;
			} else {
				actualHoursElapsed =
					(adjustedShiftEnd -
						shiftStart -
						(currentShift?.unPaidBreak
							? currentShift?.breakDuration
								? currentShift?.breakDuration * 60
								: currentShift?.breakEnd - currentShift?.breakStart
							: 0)) /
					3600;
			}
			const earnings = actualHoursElapsed * rate;
			return previousTotal + earnings;
		}, 0);
	return timeOffEarnings + shiftEarnings;
};

export function calculateAverageTakingAndCosts(
	daysWithShifts,
	toSetInState,
	organisation,
	timeOffCostForReportDays,
	setAverageTaking,
	setTotalHours,
	totalWeeklyTimeOffCost,
	setTotalRate,
	startDate,
	endDate,
	timeOff,
	extraPays,
	shifts,
	punches,
	holidayPay,
	paidTimeOff,
	filterByDepartment,
	setClockedTotalRate,
	setWeeklyLiveCost,
	validRosterDays,
	setCurrentCalenderAverage
) {
	const initialAverage = {
		takings: 0,
		targetToDate: 0,
		target: 0,
		actual: 0,
		food: 0,
		drinks: 0,
		earningsToDate: 0
	};

	let average = daysWithShifts.reduce((p, c, index) => {
		const parsedTakings = parseFloat(c?.report[0]?.takings ?? 0);
		const parsedTarget = parseFloat(c?.report[0]?.target ?? 0);
		p.takings += parseFloat(c?.average?.takings ?? 0);
		p.target += parsedTarget;
		p.actual += parsedTakings;
		if (parsedTakings !== 0) {
			p.targetToDate += parsedTarget;
			p.earningsToDate += toSetInState[index].d.e;
		}
		p.food +=
			organisation?.vat === true && organisation?.foodPercentage
				? parseFloat(c?.report[0]?.food ?? 0) - parseFloat(c?.report[0]?.food ?? 0) * (organisation?.foodPercentage / 100)
				: parseFloat(c?.report[0]?.food ?? 0);
		p.drinks +=
			organisation?.vat === true && organisation?.drinkPercentage
				? parseFloat(c?.report[0]?.drinks ?? 0) - parseFloat(c?.report[0]?.drinks ?? 0) * (organisation?.drinkPercentage / 100)
				: parseFloat(c?.report[0]?.drinks ?? 0);
		return p;
	}, initialAverage);
	average.earningsToDate += timeOffCostForReportDays;
	setAverageTaking(average);
	if (toSetInState.length) {
		const totalHours = toSetInState.reduce((p, c) => p + c.d.h, 0);
		setTotalHours(totalHours.toFixed(2).replace(/[.,]00$/, ""));
		const totalRate = parseFloat(toSetInState.reduce((p, c) => p + c.d.e, 0).toFixed(2)) + totalWeeklyTimeOffCost;
		setTotalRate(totalRate.toFixed(2).replace(/[.,]00$/, ""));

		let weeklyLiveCost = parseFloat(toSetInState.reduce((p, c) => p + c.ad.e, 0).toFixed(2)) + totalWeeklyTimeOffCost;
		weeklyLiveCost = weeklyLiveCost.toFixed(2).replace(/[.,]00$/, "");

		let weekTotalClockedRate = weeklyLiveCost;
		let today = dateMeta(new Date());
		const isTodayInRange = today.baseEpoch > startDate && today.baseEpoch < endDate;
		let todayLiveActualCost = 0;

		if (isTodayInRange) {
			let timeOffData = getTimeOffData(timeOff, today);
			const extraPayOfDay = extraPays.find((e) => e.baseDay === today.baseEpoch);
			const todayShifts = shifts.filter((k) => k.baseDay === today.baseEpoch);
			const todayPunches = punches.filter((k) => k.baseDay === today.baseEpoch);

			const actualEarnings = calculateLiveActualEarnings(
				todayShifts,
				todayPunches,
				extraPayOfDay?.newPay ?? 0,
				timeOffData,
				paidTimeOff,
				filterByDepartment
			);
			todayLiveActualCost = holidayPay ? 1.08 * actualEarnings : actualEarnings;
			weekTotalClockedRate = parseFloat(weekTotalClockedRate) - todayLiveActualCost;
		}
		setClockedTotalRate(weekTotalClockedRate);
		setWeeklyLiveCost(weeklyLiveCost);
		let validClockIns = punches.filter((p) => validRosterDays.includes(p.baseDay));
		let totalClockedInCost = calculateLiveActualEarnings(shifts, validClockIns, 0, timeOff, paidTimeOff, filterByDepartment);
		setClockedTotalRate(totalClockedInCost);
		let currentReportCount = validRosterDays.length; // Corrected to use validRosterDays

		// Correctly calculate averages based on valid roster days
		setCurrentCalenderAverage(
			daysWithShifts
				.filter((s) => validRosterDays.includes(s.baseEpoch))
				.reduce(
					(p, c) => {
						p.takings += (c.report[0].takings ?? 0) / currentReportCount;
						p.food += (c.report[0].food ?? 0) / currentReportCount;
						p.drinks += (c.report[0].drinks ?? 0) / currentReportCount;

						if (c?.report?.takings && parseFloat(c.report.takings) !== 0) {
							p.targetToDate += parseFloat(c?.report?.target ?? 0);
						}
						return p;
					},
					{ takings: 0, food: 0, drinks: 0 }
				)
		);
	}
}
